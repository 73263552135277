













































import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import NavBar from '@/components/core/nav-bar/NavBar.vue';
import InviteUserDialog from '@/components/invite-user/InviteUserDialog.vue';
import SecureLS from 'secure-ls';
import { getOperatorId, getAccountId } from '@/helpers/tokenHelpers';
import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue';
import type { AccountOperator, SideMenuLink } from '@/api/schema';
import {
  EAccountNotification,
  EOnbordingStep,
  EOnbordingStepStatus
} from '@/api/schema';
import { getOnboardingProgress } from '@/api/Onboarding';
import { checkIsTestComplete } from '@/store/modules/onboarding/OnboardingGetters';
import AppLoader from '@/components/core/AppLoader.vue';
import LightBox from '@/components/core/LightBox.vue';
import EmailVerifyButton from '@/components/EmailVerifyButton.vue';

const { COMPLETED } = EOnbordingStepStatus;

const NotificationPopup = (): any =>
  import('@/components/notification-popup/NotificationPopup.vue');
const ls = new SecureLS();

export default {
  name: 'MainView',
  components: {
    LightBox,
    NavBar,
    LayoutContentVerticalNav,
    NotificationPopup,
    InviteUserDialog,
    AppLoader,
    EmailVerifyButton
  },
  computed: {
    ...mapState('Onboarding', [
      'operators',
      'onboardingProgress',
      'progress',
      'trafficProdProgress'
    ]),
    ...mapState('Auth', ['lastActiveOperator']),
    ...mapGetters('Onboarding', [
      'operatorId',
      'operatorApproved',
      'isMobile',
      'envName',
      'isTestEnv',
      'isEmailVerified'
    ]),
    ...mapState('Operator', ['storage']),
    ...mapState('FeatureFlags', ['features']),
    ...mapState('OperatorPayments', ['balance']),
    ...mapGetters('ClientsInfo', ['isListed']),
    ...mapGetters('app', ['isCopyStakeRole']),
    ...mapState('app', ['showLoader', 'isSuperAdmin', 'isGameProviderAdmin']),
    visibleMenuItem(): unknown {
      const feature = {};

      (this.features || []).forEach((item) => {
        feature[item.name] = item.enabled !== false;
      });

      return feature;
    },
    showThresholdError(): boolean {
      const {
        minimumBalanceThreshold,
        thresholdNotificationLevel,
        currentBalance
      } = this.balance;

      if (this.isSuperAdmin && this.$route.name !== 'SuperAdminOperator') {
        return false;
      }
      if (
        thresholdNotificationLevel &&
        thresholdNotificationLevel > currentBalance
      ) {
        return true;
      }

      return (
        minimumBalanceThreshold && minimumBalanceThreshold > currentBalance
      );
    },
    showEmailConfirmWarn(): boolean {
      return (
        !this.isSuperAdmin &&
        !this.isGameProviderAdmin &&
        !this.isEmailVerified
      );
    },
    isAccount(): boolean {
      return this.$role.is('account');
    },
    isSuperAdminOrAccount(): boolean {
      return this.$role.is(['account', 'admin']) && this.isSuperAdmin;
    },
    getSuperAdminLinks(): SideMenuLink[] {
      return [
        { to: 'accounts', icon: 'mdi-account-supervisor', title: 'Accounts' },
        { to: 'custody', icon: 'mdi-archive-outline', title: 'Custody' },
        { to: 'invoices', icon: 'mdi-receipt-text', title: 'Invoices' },
        {
          to: 'providers',
          icon: 'mdi-dice-5',
          title: 'Game Providers',
          visible: this.$role.is(['super-admin', 'super-admin-owner'])
        },
        {
          to: 'roles',
          icon: 'mdi-account-multiple-check-outline',
          title: 'Roles',
          visible: this.$role.is(['super-admin', 'super-admin-owner'])
        }
      ];
    },
    getGameProviderAdminLinks(): SideMenuLink[] {
      return [
        {
          to: 'game-provider-admin-copy-stake-stats',
          icon: 'mdi-podcast',
          title: 'CopyStake Stats'
        },
        {
          to: 'game-provider-admin-reporting-api',
          icon: 'mdi-archive-outline',
          title: 'Reporting API'
        },
        {
          to: 'game-provider-admin-settings',
          icon: 'mdi-cog-outline',
          title: 'Settings'
        }
      ];
    },
    getCopyStakeRolesLinks(): SideMenuLink[] {
      return [
        {
          to: 'home',
          icon: 'mdi-chart-box-outline',
          title: 'Home',
          visible: this.$role.can.read('home')
        },
        {
          to: 'users',
          icon: 'mdi-account-group',
          title: 'Users',
          visible: this.$role.can.read('users')
        },
        { ...this.copyStakeMenuLinksGroup }
      ];
    },
    getDefaultLinks(): SideMenuLink[] {
      return [
        {
          to: 'home',
          icon: 'mdi-chart-box-outline',
          title: 'Home',
          visible: this.$role.can.read('home')
        },
        {
          to: 'loyalty-promotion',
          icon: 'mdi-bell-cog-outline',
          title: 'Loyalty Promotion',
          visible: this.$role.can.read('token_promotion')
        },
        {
          to: 'dashboard',
          icon: 'mdi-view-dashboard-outline',
          title: 'Dashboard',
          visible: this.$role.can.read('dashboard')
        },
        ...(this.isListed
          ? [
              {
                title: 'Programs',
                icon: 'mdi-file-cog-outline',
                children: [
                  {
                    to: 'play-to-earn',
                    title: 'Play to Earn',
                    visible: false
                  },
                  {
                    to: 'play-to-earn-events',
                    title: 'Play to Earn Events',
                    visible: this.isMobile
                  },
                  {
                    to: 'staking',
                    title: 'Hold to Earn',
                    visible: false
                  },
                  {
                    to: 'promo',
                    title: 'Marketing Campaigns',
                    visible:
                      this.$role.can.read('promo') &&
                      this.visibleMenuItem?.promo_management
                  },
                  {
                    to: 'account-operator-missions',
                    title: 'Missions',
                    visible: !!this.visibleMenuItem?.mission_management
                  }
                ]
              }
            ]
          : [
              {
                to: 'promo',
                title: 'Marketing Campaigns',
                icon: 'mdi-bullseye-arrow',
                visible: this.$role.can.read('token_promotion')
              }
            ]),
        {
          title: 'Token',
          icon: 'mdi-circle-multiple-outline',
          children: [
            {
              to: 'users',
              title: 'Users',
              visible: this.$role.can.read('users')
            },
            { to: 'demo-widget', title: 'Widget', visible: this.isMobile },
            {
              to: 'token-management',
              title: 'Token Management',
              visible:
                !this.isMobile &&
                this.$role.can.read('token_manage') &&
                this.isListed
            },
            {
              to: 'payments',
              title: 'Payment Limits',
              visible:
                this.$role.can.read('payments') &&
                this.isListed &&
                this.visibleMenuItem?.payment_limits
            },
            {
              to: 'withdraw-requests',
              title: 'Withdrawal Requests',
              visible:
                this.$role.can.read('withdraw') &&
                this.visibleMenuItem?.withdrawal_requests &&
                this.isListed
            },
            {
              to: 'token-sale',
              title: 'Token Sale',
              visible:
                this.$role.can.read('token_manage') &&
                (this.visibleMenuItem?.tokensale_private ||
                  this.visibleMenuItem?.tokensale_public)
            },
            {
              to: 'statistics',
              title: 'Stats',
              visible: !this.isMobile && this.$role.can.read('stats')
            }
          ]
        },
        { ...this.copyStakeMenuLinksGroup }
      ];
    },
    copyStakeMenuLinksGroup(): SideMenuLink {
      return {
        icon: 'mdi-podcast',
        title: 'CopyStake',
        visible:
          this.$role.can.read('streamers') &&
          this.visibleMenuItem?.live_streamer,
        children: [
          { title: 'Guidance', to: 'copy-stake-guidance' },
          { title: 'Streamers', to: 'copy-stake' },
          { title: 'Stats', to: 'copy-stake-stats' },
          {
            title: 'Streaming Schedule',
            to: 'copy-stake-streaming-schedule'
          },
          { title: 'Game Providers', to: 'copy-stake-game-providers' },
          { title: 'Games Whitelist', to: 'copy-stake-game-whitelist' },
          { title: 'Players Blacklist', to: 'copy-stake-user-blacklist' }
        ]
      };
    },
    menuLinks(): SideMenuLink[] {
      let menuItems: SideMenuLink[] = [];

      if (
        !this.isSuperAdmin &&
        !this.isAccount &&
        !this.operatorApproved &&
        !this.isGameProviderAdmin
      ) {
        return menuItems;
      }

      switch (true) {
        case this.isSuperAdmin:
          menuItems = this.getSuperAdminLinks;
          break;
        case this.isGameProviderAdmin:
          menuItems = this.getGameProviderAdminLinks;
          break;
        case this.isCopyStakeRole:
          menuItems = this.getCopyStakeRolesLinks;
          break;
        default:
          menuItems = this.getDefaultLinks;
      }

      return menuItems
        .filter(this.showMenuItem)
        .map((menuItem: any): SideMenuLink => {
          if (!menuItem.children) return menuItem;

          return {
            ...menuItem,
            children: menuItem.children.filter(this.showMenuItem)
          };
        });
    },
    canWatchProgress(): boolean {
      return (
        !this.isSuperAdmin &&
        this.$role.is(['account', 'admin', 'marketer', 'developer'])
      );
    }
  },
  watch: {
    operatorId: {
      handler(newId: number | null, oldId: number | null): void {
        if (newId) {
          this.getOperatorFeatureFlags();
          this.getOperatorMode();

          if (this.$role.can.read('balance')) {
            this.getOperatorBalance();
          }

          if (this.canWatchProgress) {
            this.getOnboardingProgress(newId);
          }

          if (this.$role.can.read('api get_token_price_data')) {
            this.getTokenPriceData();
          }

          if (this.$role.can.read('api get_clients_info')) {
            this.getClientsInfo();
          }

          if (!this.isSuperAdmin && this.$role.can.read('balance')) {
            this.getDynamicDepositLimitStatus();
          }
        }

        if ((newId && !oldId) || (newId && !this.isSuperAdminOrAccount)) {
          this.getOnboardingFormData();
        }
      },
      immediate: true
    },
    '$route.path': {
      handler(): void {
        if (this.operatorId && this.canWatchProgress) {
          this.getOnboardingProgress(this.operatorId);
        }
      }
    }
  },
  async mounted(): Promise<void> {
    if (!this.isSuperAdmin && !this.isGameProviderAdmin) {
      this.getAllOperators()
        .then(async () => {
          if (!this.operators.length) {
            return;
          } else {
            const lastOperator =
              this.lastActiveOperator &&
              this.operators.find(
                (operator: AccountOperator) =>
                  operator.id === this.lastActiveOperator
              );

            if (this.operators.length === 1) {
              this.setSelectedOperator(this.operators[0]);

              if (this.canWatchProgress) {
                const operator = this.operators[0];

                const data = await getOnboardingProgress(operator.id);

                this.setTrafficProdProgress(
                  data[EOnbordingStep.trafficProgress]
                );

                if (
                  this.trafficProdProgress === COMPLETED &&
                  this.$role.can.read('dashboard') &&
                  this.$router.currentRoute.path === '/home'
                ) {
                  await this.$router.push('/dashboard');
                }
              }
            } else if (lastOperator) {
              this.setSelectedOperator(lastOperator);
            } else {
              await this.checkIsTestCompleted().then(
                async (isTestCompleted: boolean) => {
                  const operatorIdx = this.operators.findIndex(
                    ({ test }) => test === !isTestCompleted
                  );
                  this.setSelectedOperator(
                    this.operators[operatorIdx !== -1 ? operatorIdx : 0]
                  );

                  if (
                    isTestCompleted &&
                    this.trafficProdProgress === COMPLETED &&
                    this.$role.can.read('dashboard') &&
                    this.$router.currentRoute.path === '/home'
                  ) {
                    await this.$router.push('/dashboard');
                  }
                }
              );
            }
          }
        })
        .finally(async () => {
          setTimeout(this.SET_LOAD_COMPLETE, 1000);
        });
    } else {
      this.SET_LOAD_COMPLETE();
    }

    if (!this.isSuperAdminOrAccount) {
      const token = ls.get('tokenKey');
      await this.setOperatorId(getOperatorId(token));
      await this.setAccountId(getAccountId(token));
    }
  },
  methods: {
    ...mapActions('Onboarding', [
      'getAllOperators',
      'getOnboardingFormData',
      'getOperatorMode',
      'getOnboardingProgress'
    ]),
    ...mapActions('TokensInfo', ['getTokenPriceData']),
    ...mapActions('ClientsInfo', ['getClientsInfo']),
    ...mapMutations('Onboarding', [
      'setSelectedOperator',
      'setTrafficProdProgress'
    ]),
    ...mapMutations('Auth', ['setOperatorId', 'setAccountId']),
    ...mapActions('OperatorPayments', ['getOperatorBalance']),
    ...mapActions('FeatureFlags', ['getOperatorFeatureFlags']),
    ...mapActions('Operator', ['getAccountStorage']),
    ...mapActions('Payments', ['getDynamicDepositLimitStatus']),
    ...mapMutations('app', ['SET_LOAD_COMPLETE']),
    showMenuItem(link: SideMenuLink): boolean {
      if (link?.visible === false) return false;
      if (link?.roles) return link.roles.includes(this.$role.name);
      return true;
    },
    async checkIsTestCompleted(): Promise<boolean> {
      if (!this.canWatchProgress) {
        return Promise.resolve(true);
      }

      const prodOperator = this.operators.find(({ test }) => !test);
      const testOperator = this.operators.find(({ test }) => test);

      if (!prodOperator || !testOperator) {
        return Promise.resolve(true);
      }

      try {
        const prodData = await getOnboardingProgress(prodOperator.id);
        this.setTrafficProdProgress(prodData[EOnbordingStep.trafficProgress]);

        if (this.trafficProdProgress === COMPLETED) {
          return Promise.resolve(true);
        }

        const testData = await getOnboardingProgress(testOperator.id);
        const isTestComplete = checkIsTestComplete(testData);

        if (isTestComplete) {
          await this.getAccountStorage();
          return this.storage.notification.includes(
            EAccountNotification.SUCCESS_ONBOARDING
          );
        }

        return Promise.resolve(isTestComplete);
      } catch (error) {
        return Promise.resolve(true);
      }
    }
  }
};
